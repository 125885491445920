import React from 'react';
import { auth } from '../../services/FirebaseAuth';

import '../../css/atoms/SignOutButton.css';

/**
 * Signs the user out of their account.
 * @param {Function} updateLoginStatus | Updates the login status.
 */
const signOut = async (updateLoginStatus) => {
    try {
        await auth.signOut();

        sessionStorage.clear();
        updateLoginStatus();
    } catch (error) {
        console.error(error);

        alert('There was an issue logging you out.');
    }
};

const SignOutButton = ({updateLoginStatus}) => (
    <button
        className="sign-out"
        onClick={() => signOut(updateLoginStatus)}
    >
        SIGN OUT
    </button>
);

export default SignOutButton;
