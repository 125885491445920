import {findByID, remove} from '../../repositories/LinkRepository';
import {create} from '../../repositories/ArchiveRepository';

/**
 * Removes a link from the links collection and adds it to the archived collection.
 * @param id
 * @return {Promise<{success: boolean}>}
 */
export default async function archiveLink(id) {
    try {
        const {destination, creator, created_at, description} = await findByID(id);


        await remove(id);
        await create(id, destination, creator, created_at, description);

        return {success: true};
    } catch (error) {
        console.error(error);

        return {success: false};
    }
}
