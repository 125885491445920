import React from 'react';

import '../../css/organisms/CardGroup.css';

const CardGroup = ({children}) => (
    <div className="card-group">
        {children}
    </div>
);

export default CardGroup;
