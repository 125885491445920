import { getAuth, signInWithPopup, GoogleAuthProvider, deleteUser, signOut } from 'firebase/auth';
import firebaseApp from './base/Firebase';
import isPSGEmail from '../helpers/isPSGEmail';

class FirebaseAuth {

    /**
     * Creates an instance of Firebase Auth
     */
    constructor(firebaseApp) {
        this.auth = getAuth(firebaseApp);
        this.provider = new GoogleAuthProvider();
    }

    /**
     * Signs a user in via Firebase Authentication + Google Sign In
     * @returns {Object} | The data about the user who has signed in.
     */
    async signIn() {
        const data = await signInWithPopup(this.auth, this.provider);

        if (data.user.emailVerified && isPSGEmail(data.user.email)) {
            return data;
        }

        await deleteUser(data.user);

        return false;
    }

    /**
     * Signs a user out via Firebase Authentication
     */
    async signOut() {
        await signOut(this.auth);
    }
}

export const auth = new FirebaseAuth(firebaseApp);
