import React, { Component } from 'react';

import SignInButton from '../atoms/SignInButton';
import Navigation from '../molecules/Navigation';
import CardGroup from '../organisms/CardGroup';
import LinkCard from '../molecules/LinkCard';

import CreateLinkForm from '../molecules/CreateLinkForm';
import readUsersLinks from '../../services/links/readUsersLinks';

import '../../css/Style.css';
import '../../css/pages/Index.css';

class Index extends Component {
    state = {
        loaded: false,
        loggedIn: false,
        usersLinks: []
    };

    /**
     * Updates the user's login status.
     */
    updateLoginStatus = async () => {
        const uid = sessionStorage.getItem('user_id');

        this.setState({
            loggedIn: uid ? true : false
        });

        if (this.state.loggedIn) { await this.getUsersLinks(uid); }
    };

    /**
     * Stores a user's links in the state.
     */
    getUsersLinks = async () => {
        this.setState({
            loaded: true,
            usersLinks: await readUsersLinks(sessionStorage.getItem('user_id'))
        });
    };

    async componentDidMount() {
        const uid = sessionStorage.getItem('user_id');

        if (uid) {
            this.setState({
                loggedIn: true
            });

            await this.getUsersLinks();
        }
    }

    render() {
        const { loggedIn, usersLinks, loaded } = this.state;

        return (
            <>
                {loggedIn && (<>
                    <header>
                        <Navigation updateLoginStatus={this.updateLoginStatus} />
                    </header>
                    <main>
                        <CreateLinkForm reload={this.getUsersLinks}/>
                        <h1 id="your-links">
                            Your Links
                        </h1>
                        {!usersLinks.length ? <p> {loaded ? "You have not created any links." : "Loading..."} </p> :
                            <CardGroup>
                                {usersLinks.map((link) =>
                                    <LinkCard
                                        reload={this.getUsersLinks}
                                        key={link.id}
                                        title={`psg.to/${link.id}`}
                                        description={link.description}
                                        destination={link.destination}
                                        createdAt={link.created_at.toDate().toString().split('GMT')[0]}
                                        id={link.id}
                                    />
                                )}
                            </CardGroup>
                        }
                    </main>
                </>)}
                {!loggedIn && (
                    <div id="sign-in-wrapper">
                        <div id="sign-in">
                            <img alt="Play Sports Group Logo" src="./LogoPSG.svg" />
                            <SignInButton updateLoginStatus={this.updateLoginStatus}/>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default Index;
