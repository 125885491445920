/**
 * Whether or not an email is of a PSG employee.
 * @param {string} email | The email to check.
 * @returns {boolean} | Whether or not the email is of a PSG employee.
 */
function isPSGEmail(email) {
    return /@playsportsnetwork\.com|@shiftactivemedia\.com/.test(email);
}

export default isPSGEmail;
