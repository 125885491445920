import React, {Component} from 'react';

import '../../css/molecules/CreateLinkForm.css'
import generateLink from '../../services/links/genererateLink';

import isURL from '../../helpers/isURL';
import findLink from '../../services/links/findLink';

class CreateLinkForm extends Component {
    defaultState = {
        id: '',
        destination: '',
        creator: sessionStorage.getItem('user_id'),
        description: '',
        error: {}
    };
    state = this.defaultState;

    /**
     * Triggered when a form input changes
     * @param {Object} event | The event data
     */
    inputChange = (event) => {
        const { name, value } = event.target;
        const { error } = this.state;

        switch(name) {
        case 'id':
            error[name] = value.match(/^[a-zA-Z]+$/) ? '' : 'Must be a-z with no spaces.';
            break;
        case 'description':
            error[name] = value ? '' : 'Must have a description.';
            break;
        case 'destination':
            error[name] = isURL(value) ? '' : 'Must be a valid URL.';
            break;
        default:
            break;
        }

        this.setState({
            [name]: name === 'id' ? value.toLowerCase() : value
        });
    };

    /**
     * Adds a link to the database
     * @return {Promise<void>}
     */
    addLink = async () => {
        try {
            const { error, id, destination, creator, description } = this.state;
            const { success: linkExists } = await findLink(id);
            const valuesAreUndefined = [id, description, description].includes(undefined);
            const hasErrors = !Object.values(error).includes('');

            if (linkExists) {
                return alert(`The short link psg.to/${id} already exists.`);
            }

            if (valuesAreUndefined || hasErrors) {
                return alert('You must complete the form before creating a link.');
            }

            const { success } = await generateLink(id, destination, description, creator);


            if (success) {
                this.props.reload();
                this.setState(this.defaultState);

                alert(`Created link psg.to/${id} which goes to ${destination}.`);
            } else {
                alert('There was an error creating the link.');
            }
        } catch (error) {
            console.error(error);

            alert('There was an error creating the link.');
        }
    };

    render() {
        const { error, id, destination, description } = this.state;

        return (
            <section className="create-link-form">
                <h2>
                    Create A Short Link
                </h2>

                <p>
                    {id && destination ? <code>psg.to/{id} → {destination}</code> : ''}
                </p>

                <form>
                    <label>
                        Short URL <br />
                        <input
                            type="text"
                            name="id"
                            onChange={this.inputChange}
                            value={id}
                        />
                        <span className="input-error">{error.id}</span>
                    </label>
                    <label>
                        Long URL <br />
                        <input
                            type="url"
                            name="destination"
                            onChange={this.inputChange}
                            value={destination}
                        />
                        <span className="input-error">{error.destination}</span>
                    </label>
                    <label className="description">
                        Description <br />
                        <input
                            type="text"
                            name="description"
                            onChange={this.inputChange}
                            value={description}
                        />
                        <span className="input-error">{error.description}</span>
                    </label>
                    <button type="button" onClick={this.addLink}>
                        Create Link
                    </button>
                </form>
            </section>
        );
    }
}

export default CreateLinkForm;
