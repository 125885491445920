import React from 'react';

import '../../css/molecules/Card.css';

const Card = ({title, description, children}) => (
    <div className="card">
        <h2 className="card-title">
           {title}
        </h2>
        <p className="card-description">
            {description}
        </p>
        {children}
    </div>
);

export default Card;
