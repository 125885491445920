import React from 'react';

import Card from './Card';

import '../../css/molecules/Card.css';
import DeleteLinkButton from '../atoms/DeleteLinkButton';

const LinkCard = ({reload, title, description, destination, createdAt, id}) => (
    <Card
        title={title}
        description={description}
    >
        <table className="link-details">
            <tbody>
                <tr>
                    <th>Destination</th>
                    <td>{destination}</td>
                </tr>
                <tr>
                    <th>Created</th>
                    <td>{createdAt}</td>
                </tr>
            </tbody>
        </table>
        <div className="link-actions">
            <DeleteLinkButton id={id} reload={reload}/>
        </div>
    </Card>
);

export default LinkCard;
