import { getFirestore, doc, setDoc, collection, query, where, getDocs, getDoc, deleteDoc } from 'firebase/firestore'
import firebaseApp from '../services/base/Firebase';

const firestore = getFirestore(firebaseApp);

/**
 * Creates a new link
 * @param {string} id | The id of the link
 * @param {string} destination | The URL that the link redirects to
 * @param {string} creator | The Firebase UID of the person creating the link
 * @param {string?} description | A description of the link
 * @return {Promise<{success: boolean}>} | Whether or not the creation was successful
 */
export async function create(id, destination, description, creator) {
    const linksRef = doc(firestore, 'links', id);

    try {
        await setDoc(linksRef, {
            destination,
            creator,
            created_at: new Date(),
            description: description || null
        });

        return {success: true};
    } catch (error) {
        console.error(error);

        return {success: false};
    }
}

/**
 * Gets a list of links a user has created
 * @param {string} uid | The Firebase ID of the user.
 * @returns {Object[]} | The links a user has created.
 */
export async function findAllByUser(uid) {
    const linksRef = collection(firestore, 'links');
    const q = query(linksRef, where('creator', '==', uid));
    const snapshot = await getDocs(q);

    return snapshot.docs.map(
        (doc) => ({
            id: doc.id,
            ...doc.data()
        })
    );
}

/**
 * Gets a link based of a given ID.
 * @param id | The ID of the link the user wants.
 * @return {Object} | The link the user is looking for.
 */
export async function findByID(id) {
    const linksRef = doc(firestore, 'links', id);
    const link = await getDoc(linksRef);

    if (link.exists()) {
        return {
            success: true,
            id,
            ...link.data()
        };
    }

    return {
        success: false
    }
}

/**
 * Deletes a link and archives it.
 * @param {string} id | The ID of the link to delete
 */
export async function remove(id) {
    try {
        const linksRef = doc(firestore, 'links', id);
        await deleteDoc(linksRef);

        return {
            success: true
        };
    } catch (error) {
        console.error(error);

        return {
            success: false
        };
    }
}

