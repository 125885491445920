import React from "react";

import SignOutButton from '../atoms/SignOutButton';

import '../../css/molecules/Navigation.css';

const Navigation = ({updateLoginStatus}) => (
    <nav>
        <div className="nav-content">
            <div className="nav-logo">
                <img
                    className="logo"
                    alt="Play Sports Group Logo"
                    src="./LogoPSG.svg"
                />
            </div>
            <div className="nav-items">
                <SignOutButton updateLoginStatus={updateLoginStatus} />
            </div>
        </div>
    </nav>
);

export default Navigation;
