import {getFirestore, doc, collection, setDoc} from 'firebase/firestore';
import firebaseApp from '../services/base/Firebase';

const firestore = getFirestore(firebaseApp);

/**
 * @param {string} id | The id of the link
 * @param {string} destination | The URL that the link redirects to
 * @param {string} creator | The Firebase UID of the person creating the link
 * @param {Date} created_at | The time that the link was created at
 * @param {string?} description | A description of the link
 * @return {Promise<{success: boolean}>}
 */
export async function create(id, destination, creator, created_at, description) {
    const archiveRef = doc(collection(firestore, 'archived'));

    try {
        await setDoc(archiveRef, {
            id,
            destination,
            creator,
            created_at,
            description,
            deleted_at: new Date()
        });

        return {success: true}
    } catch (error) {
        console.error(error);

        return {success: false}
    }
}
