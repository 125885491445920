import React from 'react';
import { auth } from "../../services/FirebaseAuth";

/**
 * Signs the user into their account.
 * @param {Function} updateLoginStatus | Updates the login status.
 */
const signIn = async (updateLoginStatus) => {
    try {
        const { user } = await auth.signIn();

        sessionStorage.setItem('user_id', user.uid);

        updateLoginStatus();
    } catch {
        alert('There was an issue logging you in.');
    }
};

const SignInButton = ({updateLoginStatus}) => (
    <button
        className="sign-in"
        onClick={() => signIn(updateLoginStatus)}
    >
        Sign In
    </button>
);

export default SignInButton;
