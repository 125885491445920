import React from 'react';
import archiveLink from '../../services/links/archiveLink';

import '../../css/atoms/DeleteLinkButton.css'

/**
 * Deletes a link and archives it.
 * @param {string} id | The ID of the link to delete
 * @param {function} reload | Reloads the content on the dashboard
 *                            so that the link no longer appears.
 */
const deleteLinkOnClick = async (id, reload) => {
    const deletedLink = await archiveLink(id);

    if (deletedLink.success) {
        alert('Link deleted');
    } else {
        alert(`There was a problem deleting the link. \n ${deletedLink.error ? deletedLink.error : ""}`);
    }

    reload();
};

const DeleteLinkButton = ({id, reload}) => (
    <button
        type="button"
        className="delete-link"
        onClick={() => deleteLinkOnClick(id, reload)}
    >
        DELETE
    </button>
);

export default DeleteLinkButton;
